import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import AudioPlayer from "../../../components/audio-player"
import Breadcrumb from "../../../components/breadcrumb"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Inline from "../../../components/inline"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import PaperStack from "../../../components/paper-stack"
import Poem from "../../../components/poem"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopJuliPageQuery {
      materialien: file(
        relativePath: { eq: "workshops/kalender/juli/materialien.jpg" }
      ) {
        ...largeImage
      }
      postkarte: file(relativePath: { eq: "postkarte-sommer.pdf" }) {
        publicURL
      }
      beispiel01: file(
        relativePath: { eq: "workshops/kalender/juli/beispiel-1.jpg" }
      ) {
        ...largeImage
      }
      beispiel02: file(
        relativePath: { eq: "workshops/kalender/juli/beispiel-2.jpg" }
      ) {
        ...largeImage
      }
      beispiel03: file(
        relativePath: { eq: "workshops/kalender/juli/beispiel-3.jpg" }
      ) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-juli">
      <Seo
        title="Juli"
        description="In Hölderlins Gedicht ›Der Sommer‹ schimmert und flimmert es nur so am Himmel. Es beginnt nicht mit einem strahlend hellen Sommertag, sondern mit einer Sternennacht. Mach es ihm nach und zaubere einen Sommernachtshimmel auf deine Kalenderseite!"
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "Juli",
              link: "/workshops/kalender/juli",
            },
          ]}
        />
        <Stack space={[24, 24, 32]}>
          <Stack>
            <PageTitle>Juli</PageTitle>
            <Paragraph dropcap={true}>
              In Hölderlins Gedicht ›Der Sommer‹ schimmert und flimmert es nur
              so am Himmel. Es beginnt nicht mit einem strahlend hellen
              Sommertag, sondern mit einer Sternennacht. Mach es ihm nach und
              zaubere einen Sommernachtshimmel auf deine Kalenderseite!
            </Paragraph>
          </Stack>

          <Constrain>
            <Stack>
              <Paragraph>Lies das Gedicht zunächst einmal laut vor.</Paragraph>
              <PaperStack>
                <Stack space={6}>
                  <Heading as="div" level={5}>
                    Der Sommer
                  </Heading>
                  <Poem size={[3, 3, 4]}>
                    <p>Das Erntefeld erscheint, auf Höhen schimmert</p>
                    <p>Der hellen Wolke Pracht, indes am weiten Himmel</p>
                    <p>In stiller Nacht die Zahl der Sterne flimmert,</p>
                    <p>Groß ist und weit von Wolken das Gewimmel.</p>
                    <br />
                    <p>Die Pfade gehn entfernter hin, der Menschen Leben,</p>
                    <p>Es zeiget sich auf Meeren unverborgen,</p>
                    <p>Der Sonne Tag ist zu der Menschen Streben</p>
                    <p>Ein hohes Bild, und golden glänzt der Morgen.</p>
                    <br />
                    <p>Mit neuen Farben ist geschmückt der Gärten Breite,</p>
                    <p>Der Mensch verwundert sich, daß sein Bemühn gelinget,</p>
                    <p>
                      Was er mit Tugend schafft, und was er hoch vollbringet,
                    </p>
                    <p>Es steht mit der Vergangenheit in prächtigem Geleite.</p>
                  </Poem>
                </Stack>
              </PaperStack>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Unsere Idee für deine Kalenderseite...
              </Heading>
              <AudioPlayer src={`workshops/kalender/juli`} />
              <Heading as="p" align="center">
                Dinge, die Hölderlin mit dem Himmel verbindet ...
              </Heading>
              <Inline as="ul" alignX="center">
                <Text sans={true} size={[2, 3, 4]}>
                  Himmelstochter
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Himmelsflamme
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  himmelhoch
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Himmelstöne
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Himmelslüfte
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Himmelskräfte
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Winterhimmel
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Himmelsgaben
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Himmelsblume
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Himmelsaugenblike
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  himmelwärts
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Himmelswonne
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Frühlingshimmel
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Himmelsruh
                </Text>
              </Inline>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Du brauchst...
              </Heading>
              <Paragraph>
                Alles, was leuchtet, glitzert und strahlt. Zum Beispiel
                leuchtende Buntstifte, Sticker, Weihnachts-Geschenkpapier oder
                alte Verpackungsmaterialien.{" "}
              </Paragraph>
              <Image
                image={data.materialien.childImageSharp.gatsbyImageData}
                alt="Die Anleitung zum Kalenderblatt, das Sommer-Gedicht und Klebestreifen mit Glitzersternen"
              />
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Deine Aufgabe Schritt für Schritt:
              </Heading>
              <Stack space={6}>
                <StepsBox step={1}>
                  <Paragraph>
                    Klebe die Postkarte mit{" "}
                    <TextLink href={data.postkarte.publicURL}>
                      Hölderlins Sommer-Gedicht
                    </TextLink>{" "}
                    auf deine Kalenderseite. Lasse daneben oder darunter genug
                    Platz zum Malen und Kleben.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={2}>
                  <Paragraph>
                    Denk dir eine warme Sommernacht. Du liegst auf der Wiese und
                    schaust in den Himmel. Was könnte sich dort oben abspielen?
                  </Paragraph>
                </StepsBox>
                <StepsBox step={3}>
                  <Stack space={6}>
                    <Paragraph>
                      Zeichne, was dir dazu eingefallen ist, auf die
                      Kalenderseite. Schneide aus alten Verpackungsmaterialien
                      oder Weihnachts-Geschenkpapier weitere Sterne und
                      Himmelskörper aus. Du kannst auch Sticker dazukleben,
                      falls du welche hast.
                    </Paragraph>
                  </Stack>
                </StepsBox>
                <StepsBox step={4}>
                  <Paragraph>
                    Schreibe zuletzt einen Wunsch, für diejenige/denjenigen,
                    die/der den Kalender bekommt, in den Sternenhimmel. Wenn du
                    den Kalender für dich selbst bastelst, kannst auch einen
                    Wunsch für dich dort aufschreiben.
                  </Paragraph>
                </StepsBox>
              </Stack>
            </Stack>
          </Constrain>

          <Stack space={6}>
            <Heading as="h2" level={4} align="center">
              So kann deine Kalenderseite aussehen:
            </Heading>
            <Grid columns={[1, 2]} space={3} alignX="center">
              <Image
                image={data.beispiel01.childImageSharp.gatsbyImageData}
                alt="Detailansicht der fertigen Kalenderseite. Ein Sommerhimmel auf weißem Hintergrund"
              />
              <Image
                image={data.beispiel02.childImageSharp.gatsbyImageData}
                alt="Ein weitere Detailansicht der fertigen Kalenderseite. Diesmal mit dunklem Hintergrund"
              />
              <Image
                image={data.beispiel03.childImageSharp.gatsbyImageData}
                alt="Beide Kalenderseiten liegen auf einem Holztisch nebeneinander"
              />
            </Grid>
          </Stack>

          <Constrain>
            <Stack>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
